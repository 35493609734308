var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Dashboard"}},[_c('div',{staticClass:"px-5 mt-1 d-flex"},[_c('div',[_c('div',{staticClass:"dashboard-widget count-widget-all pa-10",on:{"click":function($event){return _vm.goToScreen('/distributors')}}},[_c('h2',{staticClass:"text-h2 mb-5 "},[_vm._v(_vm._s(_vm.distributors))]),_c('h6',{staticClass:"text-h6 "},[_vm._v("Distributors")])])]),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"dashboard-widget count-widget pa-10",on:{"click":function($event){return _vm.goToScreen('/routes')}}},[_c('h2',{staticClass:"text-h2 mb-5"},[_vm._v(_vm._s(_vm.routes))]),_c('h6',{staticClass:"text-h6 "},[_vm._v("Routes")])])]),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"dashboard-widget count-widget-assign pa-10",on:{"click":function($event){return _vm.goToScreen('/customers')}}},[_c('h2',{staticClass:"text-h2 mb-5"},[_vm._v(_vm._s(_vm.customers))]),_c('h6',{staticClass:"text-h6 "},[_vm._v("Customers")])])])]),_c('v-row',{staticClass:"mt-4 mx-1"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('h5',[_vm._v("Best Selling Products (By Weight)")]),_c('v-spacer'),_c('datePicker',{attrs:{"opens":"left"},on:{"date":function($event){_vm.top10ProductsByWeightDate = $event}}})],1),_c('v-data-table',{staticClass:"clickable-row",attrs:{"headers":_vm.productHeadersByWeight,"items":_vm.top10ProductsByWeight,"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("No Products Available")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("No Products Available")]},proxy:true},{key:"item.totalAmountData",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currencyFilter")(Number(item.totalAmountData))))]}},{key:"item.averageAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currencyFilter")((item.totalAmountData/item.totalQuantity))))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.image)?_c('img',{staticClass:"my-3 ml-2",attrs:{"width":"50","alt":"imges not found","src":_vm.imgWpx(item.image, 200)},on:{"click":function($event){return _vm.openGallery([{ image: item.image }])}}}):_c('v-icon',{staticClass:"my-3",attrs:{"size":"66","color":"blue-grey lighten-5"}},[_vm._v("mdi-image")]),_c('span',{staticClass:"ml-3 mt-8 font-weight-bold text-subtitle-1",style:({ color: item.colorCode ? item.colorCode : '#3172d0' }),attrs:{"title":item.name}},[_vm._v(_vm._s(item.name || '-'))])],1)]}},{key:"item.totalProductKg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalProductKg || 0)+" ")]}}])},[(_vm.top10ProductsByWeight.length)?_c('template',{slot:"body.append"},[_c('tr',{staticStyle:{"color":"teal","font-weight":"bolder"}},[_c('td',[_vm._v("Summary")]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyFilter")(_vm.totalProductsByWeightPrice)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.totalProdcutsByWeightQty || '-'))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatNumber(_vm.totalProdcutsKgByWeight) || 0))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyFilter")(_vm.totalProductsByWeightPrice / _vm.totalProdcutsByWeightQty || '-'))+" ")])])]):_vm._e()],2)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('h5',[_vm._v("Best Selling Products (By Amount)")]),_c('v-spacer'),_c('datePicker',{attrs:{"opens":"left"},on:{"date":function($event){_vm.top10ProductsByAmountDate = $event}}})],1),_c('v-data-table',{staticClass:"clickable-row",attrs:{"headers":_vm.productHeaders,"items":_vm.top10ProductsByAmount,"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("No Products Available")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("No Products Available")]},proxy:true},{key:"item.totalAmountData",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currencyFilter")(Number(item.totalAmountData))))]}},{key:"item.averageAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currencyFilter")((item.totalAmountData/item.totalQuantity))))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.image)?_c('img',{staticClass:"my-3 ml-2",attrs:{"width":"50","alt":"imges not found","src":_vm.imgWpx(item.image, 200)},on:{"click":function($event){return _vm.openGallery([{ image: item.image }])}}}):_c('v-icon',{staticClass:"my-3",attrs:{"size":"66","color":"blue-grey lighten-5"}},[_vm._v("mdi-image")]),_c('span',{staticClass:"ml-3 mt-8 font-weight-bold text-subtitle-1",style:({ color: item.colorCode ? item.colorCode : '#3172d0' }),attrs:{"title":item.name}},[_vm._v(_vm._s(item.name || '-'))])],1)]}}])},[(_vm.top10ProductsByAmount.length)?_c('template',{slot:"body.append"},[_c('tr',{staticStyle:{"color":"teal","font-weight":"bolder"}},[_c('td',[_vm._v("Summary")]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyFilter")(_vm.totalProductsPrice)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.totalProdcutsQty || '-'))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyFilter")(_vm.formatNumber(_vm.totalProductsPrice / _vm.totalProdcutsQty) || '-'))+" ")])])]):_vm._e()],2)],1)],1)],1),_c('v-row',{staticClass:"mt-4 mx-1"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('h5',[_vm._v("Top Customers")]),_c('v-spacer'),_c('datePicker',{attrs:{"opens":"left"},on:{"date":function($event){_vm.top10CustomersDate = $event}}})],1),_c('v-data-table',{staticClass:"clickable-row",attrs:{"headers":_vm.customerHeaders,"items":_vm.top10CustomersByAmount,"hide-default-footer":""},on:{"click:row":_vm.viewDistributor},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("No Customers Available")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("No Customers Available")]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-4"},[_c('span',{},[_vm._v(_vm._s(item.name || '-'))]),_c('br'),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.userName || '-'))])])]}},{key:"item.totalAmountData",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currencyFilter")(Number(item.totalAmountData))))]}},{key:"item.averageAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currencyFilter")((item.totalAmountData/item.totalQuantity))))]}}])},[(_vm.top10CustomersByAmount.length)?_c('template',{slot:"body.append"},[_c('tr',{staticStyle:{"color":"teal","font-weight":"bolder"}},[_c('td',[_vm._v("Summary")]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyFilter")(_vm.totalCustomersPrice)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.totalCustomersQty || '-'))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyFilter")(_vm.formatNumber(_vm.totalCustomersPrice / _vm.totalCustomersQty) || '-'))+" ")])])]):_vm._e()],2)],1)],1)],1),_c('gallery',{attrs:{"id":"gallery-dashboard","images":_vm.galleryImages,"index":_vm.galleryIndex},on:{"close":function($event){_vm.galleryIndex=null}}}),(_vm.viewDistributorDialog)?_c('DistributorDetail',{attrs:{"permissions":_vm.permissions,"distributorId":_vm.distributor.id,"distributorDetailDialog":_vm.viewDistributorDialog},on:{"cancel":function($event){_vm.viewDistributorDialog=false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }