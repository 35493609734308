<template>
    <dateRange v-model="date" auto-apply :ranges="dateOptions" :opens="opens" :appendToBody="appendToBody" @update="onUpdate">
        <template v-slot:input="picker" class="d-flex">
            <v-icon class="mr-3">mdi-calendar-blank</v-icon>
            <template v-if="moment(picker.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
                            moment(picker.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')">Today</template>
            <template v-else-if="moment(picker.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD') &&
                            moment(picker.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD')">Yesterday</template>
            <template v-else-if="moment(picker.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'days').format('YYYY-MM-DD') &&
                            moment(picker.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')">Last 1 day</template>
            <template v-else-if="moment(picker.startDate).format('YYYY-MM-DD') === moment().subtract(7, 'days').format('YYYY-MM-DD') &&
                            moment(picker.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')">Last 7 days</template>
            <template v-else-if="moment(picker.startDate).format('YYYY-MM-DD') === moment().subtract(30, 'days').format('YYYY-MM-DD') &&
                            moment(picker.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')">Last 30 days</template>
            <template v-else-if="moment(picker.startDate).format('YYYY-MM-DD') === moment().startOf('month').format('YYYY-MM-DD') &&
                            moment(picker.endDate).format('YYYY-MM-DD') === moment().endOf('month').format('YYYY-MM-DD')">This Month</template>
            <template v-else-if="moment(picker.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') &&
                            moment(picker.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')">Last Month</template>
            <template v-else>{{ picker.startDate | dateFormat }} - {{ picker.endDate | dateFormat }}</template>
        </template>
    </dateRange>
</template>

<script>
import moment from 'moment'
import dateRange from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        name: "datePicker",
        components: {
            dateRange
        },
        props: {
            initial: {type: [Object], default: () => ({startDate: moment(), endDate: moment()})},
            opens: {type: String, default: 'center'},
            appendToBody: {type: Boolean, default: false},
        },
        data(){
            return{
                moment: moment,
                dateOptions: {
                    'Today': [moment().toDate(), moment().toDate()],
                    'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                    'Last 1 day': [moment().subtract(1, 'days').toDate(), moment().toDate()],
                    'Last 7 days': [moment().subtract(7, 'days').toDate(), moment().toDate()],
                    'Last 30 days': [moment().subtract(30, 'days').toDate(), moment().toDate()],
                    'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
                    'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
                },
                date: this.initial
            }
        },
        watch:{
            'initial':function (val) {
               this.date = val
            }
        },
        methods:{
            onUpdate(){
                let isLastNDays = false
                if(moment(this.date.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
                    moment(this.date.startDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')){
                      isLastNDays = true
                }
                this.$emit('date', this.date)
                this.$emit('isLastNDays', isLastNDays)
            }
        }
    }
</script>

<style>
/deep/.vue-daterange-picker .dropdown-menu{
    top: 40px;
}

</style>

