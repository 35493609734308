var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dateRange',{attrs:{"auto-apply":"","ranges":_vm.dateOptions,"opens":_vm.opens,"appendToBody":_vm.appendToBody},on:{"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-calendar-blank")]),(_vm.moment(picker.startDate).format('YYYY-MM-DD') === _vm.moment().format('YYYY-MM-DD') &&
                        _vm.moment(picker.endDate).format('YYYY-MM-DD') === _vm.moment().format('YYYY-MM-DD'))?[_vm._v("Today")]:(_vm.moment(picker.startDate).format('YYYY-MM-DD') === _vm.moment().subtract(1, 'days').format('YYYY-MM-DD') &&
                        _vm.moment(picker.endDate).format('YYYY-MM-DD') === _vm.moment().subtract(1, 'days').format('YYYY-MM-DD'))?[_vm._v("Yesterday")]:(_vm.moment(picker.startDate).format('YYYY-MM-DD') === _vm.moment().subtract(1, 'days').format('YYYY-MM-DD') &&
                        _vm.moment(picker.endDate).format('YYYY-MM-DD') === _vm.moment().format('YYYY-MM-DD'))?[_vm._v("Last 1 day")]:(_vm.moment(picker.startDate).format('YYYY-MM-DD') === _vm.moment().subtract(7, 'days').format('YYYY-MM-DD') &&
                        _vm.moment(picker.endDate).format('YYYY-MM-DD') === _vm.moment().format('YYYY-MM-DD'))?[_vm._v("Last 7 days")]:(_vm.moment(picker.startDate).format('YYYY-MM-DD') === _vm.moment().subtract(30, 'days').format('YYYY-MM-DD') &&
                        _vm.moment(picker.endDate).format('YYYY-MM-DD') === _vm.moment().format('YYYY-MM-DD'))?[_vm._v("Last 30 days")]:(_vm.moment(picker.startDate).format('YYYY-MM-DD') === _vm.moment().startOf('month').format('YYYY-MM-DD') &&
                        _vm.moment(picker.endDate).format('YYYY-MM-DD') === _vm.moment().endOf('month').format('YYYY-MM-DD'))?[_vm._v("This Month")]:(_vm.moment(picker.startDate).format('YYYY-MM-DD') === _vm.moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') &&
                        _vm.moment(picker.endDate).format('YYYY-MM-DD') === _vm.moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))?[_vm._v("Last Month")]:[_vm._v(_vm._s(_vm._f("dateFormat")(picker.startDate))+" - "+_vm._s(_vm._f("dateFormat")(picker.endDate)))]]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})}
var staticRenderFns = []

export { render, staticRenderFns }