<template>
  <div id="Dashboard">
    <div class="px-5 mt-1 d-flex">
      <div>
        <div @click="goToScreen('/distributors')" class="dashboard-widget count-widget-all pa-10">
          <h2 class="text-h2 mb-5 ">{{ distributors }}</h2>
          <h6 class="text-h6 ">Distributors</h6>
        </div>
      </div>
      <div class="ml-3">
        <div @click="goToScreen('/routes')" class="dashboard-widget count-widget pa-10">
          <h2 class="text-h2 mb-5">{{ routes }}</h2>
          <h6 class="text-h6 ">Routes</h6>
        </div>
      </div>
      <div class="ml-3">
        <div @click="goToScreen('/customers')" class="dashboard-widget count-widget-assign pa-10">
          <h2 class="text-h2 mb-5">{{ customers }}</h2>
          <h6 class="text-h6 ">Customers</h6>
        </div>
      </div>
    </div>

    <v-row class="mt-4 mx-1">
      <v-col cols="6">
        <v-card class="pa-4">
          <v-card-title>
            <h5>Best Selling Products (By Weight)</h5><v-spacer />
            <datePicker @date="top10ProductsByWeightDate = $event" opens="left" />
          </v-card-title>
          <v-data-table :headers="productHeadersByWeight" :items="top10ProductsByWeight" hide-default-footer class="clickable-row">
            <template v-slot:no-data>No Products Available</template>
            <template v-slot:no-results>No Products Available</template>
            <template v-slot:item.totalAmountData="{ item }">{{ Number(item.totalAmountData) | currencyFilter }}</template>
            <template v-slot:item.averageAmount="{ item }">{{ (item.totalAmountData/item.totalQuantity) | currencyFilter }}</template>
           
            <template v-slot:item.name="{ item }">
              <div class="d-flex">
                <img
                  v-if="item.image"
                  @click="openGallery([{ image: item.image }])"
                  width="50"
                  class="my-3 ml-2"
                  alt="imges not found"
                  :src="imgWpx(item.image, 200)"
                />
                <v-icon v-else size="66" color="blue-grey lighten-5" class="my-3">mdi-image</v-icon>
                <span :title="item.name" class="ml-3 mt-8 font-weight-bold text-subtitle-1" 
                  :style="{ color: item.colorCode ? item.colorCode : '#3172d0' }"
                >{{ item.name || '-'}}</span>
              </div>
            </template>
            <template v-slot:item.totalProductKg="{ item }">
              {{ item.totalProductKg || 0 }}
            </template>
            
            <template slot="body.append" v-if="top10ProductsByWeight.length">
              <tr style="color: teal; font-weight: bolder">
                <td>Summary</td>
                <td class="text-end">{{ totalProductsByWeightPrice | currencyFilter }}</td>
                <td class="text-end">{{ totalProdcutsByWeightQty || '-' }}</td>
                <td class="text-end">{{ formatNumber(totalProdcutsKgByWeight) || 0 }}</td>
                <td class="text-end">{{ totalProductsByWeightPrice / totalProdcutsByWeightQty || '-' | currencyFilter }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-4">
          <v-card-title>
            <h5>Best Selling Products (By Amount)</h5><v-spacer />
            <datePicker @date="top10ProductsByAmountDate = $event" opens="left" />
          </v-card-title>
          <v-data-table :headers="productHeaders" :items="top10ProductsByAmount" hide-default-footer class="clickable-row">
            <template v-slot:no-data>No Products Available</template>
            <template v-slot:no-results>No Products Available</template>
            <template v-slot:item.totalAmountData="{ item }">{{ Number(item.totalAmountData) | currencyFilter }}</template>
            <template v-slot:item.averageAmount="{ item }">{{ (item.totalAmountData/item.totalQuantity) | currencyFilter }}</template>
           
            <template v-slot:item.name="{ item }">
              <div class="d-flex">
                <img
                  v-if="item.image"
                  @click="openGallery([{ image: item.image }])"
                  width="50"
                  class="my-3 ml-2"
                  alt="imges not found"
                  :src="imgWpx(item.image, 200)"
                />
                <v-icon v-else size="66" color="blue-grey lighten-5" class="my-3">mdi-image</v-icon>
                <span :title="item.name" class="ml-3 mt-8 font-weight-bold text-subtitle-1" 
                  :style="{ color: item.colorCode ? item.colorCode : '#3172d0' }"
                >{{ item.name || '-'}}</span>
              </div>
            </template>
            
            <template slot="body.append" v-if="top10ProductsByAmount.length">
              <tr style="color: teal; font-weight: bolder">
                <td>Summary</td>
                <td class="text-end">{{ totalProductsPrice | currencyFilter }}</td>
                <td class="text-end">{{ totalProdcutsQty || '-' }}</td>
                <td class="text-end">{{ formatNumber(totalProductsPrice / totalProdcutsQty) || '-' | currencyFilter }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-4 mx-1">
      <v-col cols="6">
        <v-card class="pa-4">
          <v-card-title>
            <h5>Top Customers</h5><v-spacer />
            <datePicker @date="top10CustomersDate = $event" opens="left" />
          </v-card-title>
          <v-data-table :headers="customerHeaders" :items="top10CustomersByAmount" @click:row="viewDistributor" hide-default-footer class="clickable-row">
            <template v-slot:no-data>No Customers Available</template>
            <template v-slot:no-results>No Customers Available</template>
            <template v-slot:item.name="{ item }">
              <div class="my-4">
                <span class="">{{ item.name || '-' }}</span>
              <br />
              <span class="grey--text">{{ item.userName || '-' }}</span>
            </div>
            </template>
            <template v-slot:item.totalAmountData="{ item }">{{ Number(item.totalAmountData) | currencyFilter }}</template>
            <template v-slot:item.averageAmount="{ item }">{{ (item.totalAmountData/item.totalQuantity) | currencyFilter }}</template>
           
            <template slot="body.append" v-if="top10CustomersByAmount.length">
              <tr style="color: teal; font-weight: bolder">
                <td>Summary</td>
                <td class="text-end">{{ totalCustomersPrice | currencyFilter }}</td>
                <td class="text-end">{{ totalCustomersQty || '-' }}</td>
                <td class="text-end">{{ formatNumber(totalCustomersPrice / totalCustomersQty) || '-' | currencyFilter }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <gallery id="gallery-dashboard" :images="galleryImages" :index="galleryIndex" @close="galleryIndex=null"/>
    <DistributorDetail v-if="viewDistributorDialog" :permissions="permissions" :distributorId="distributor.id" :distributorDetailDialog="viewDistributorDialog" @cancel="viewDistributorDialog=false"></DistributorDetail>
  </div>
</template>

<script>
import { SUBSCRIBE_CUSTOMERS_COUNT, SUBSCRIBE_DISTRIBUTORS_COUNT } from '@/graphql/subscriptions/Organizations'
import { IDS } from '@/Constants/IDS'
import datePicker from '../components/Common/DatePicker.vue'
import moment from 'moment';
import axios from 'axios';
import gallery from "../mixins/gallery";
import Constants from "@/Constants/Constants";
import DistributorDetail from '@/components/Distributors/DistributorDetail'
import { SUBSCRIBE_ROUTES_COUNT } from '@/graphql/subscriptions/Routes'

export default {
  name: 'Dashboard',
  components: {
    datePicker,
    DistributorDetail
  },

  mixins: [gallery],

  data() {
    return { 
      distributors: 0,
      customers: 0,
      routes: 0,
      permissions: {},
      viewDistributorDialog: false,
      distributor: null,
      top10ProductsByAmountDate: {startDate: moment(), endDate: moment()},
      top10ProductsByWeightDate: {startDate: moment(), endDate: moment()},
      top10CustomersDate: {startDate: moment(), endDate: moment()},
      top10ProductsByAmount: [],
      top10ProductsByWeight: [],
      top10CustomersByAmount: [],
      totalProdcutsQty: 0, 
      totalProductsPrice: 0,
      totalProdcutsByWeightQty: 0,
      totalProdcutsKgByWeight: 0, 
      totalProductsByWeightPrice: 0,
      totalCustomersQty: 0, 
      totalCustomersPrice: 0,
      galleryImages: [],
      galleryIndex: null, 
      productHeaders: [
          { text: 'PRODUCT NAME', value: 'name', width:370 },
          { text: 'TOTAL AMOUNT', value: 'totalAmountData', align:'end' },
          { text: 'QUANTITY', value: 'totalQuantity', align:'end' },
          { text: 'AVG. AMOUNT', value: 'averageAmount', align:'end', sortable: false }
      ],
      productHeadersByWeight: [
          { text: 'PRODUCT NAME', value: 'name', width:370 },
          { text: 'TOTAL AMOUNT', value: 'totalAmountData', align:'end' },
          { text: 'QUANTITY', value: 'totalQuantity', align:'end' },
          { text: 'TOTAL PRODUCT KG', value: 'totalProductKg', align:'end' },
          { text: 'AVG. AMOUNT', value: 'averageAmount', align:'end', sortable: false }
      ],
      customerHeaders: [
          { text: 'CUSTOMER NAME', value: 'name', width:250 },
          { text: 'TOTAL AMOUNT', value: 'totalAmountData', align:'end' },
          { text: 'QUANTITY', value: 'totalQuantity', align:'end' },
          { text: 'AVG. AMOUNT', value: 'averageAmount', align:'end', sortable: false }
      ],
    }
  },
  apollo: {
    $subscribe: {
      Distributors: {
        query: SUBSCRIBE_DISTRIBUTORS_COUNT,
        variables () { return { orgType: [IDS.organizationTypes.Distributor] }},
        result ({ data }) { this.distributors = data.Distributors.aggregate.count },
      },
      Customers: {
        query: SUBSCRIBE_CUSTOMERS_COUNT,
        variables () { return { orgType: [IDS.organizationTypes.Retailer, IDS.organizationTypes.Dealer] }},
        result ({ data }) { this.customers = data.Customers.aggregate.count },
      }, Routes: {
        query: SUBSCRIBE_ROUTES_COUNT,
        result ({ data }) { this.routes = data.Routes.aggregate.count },
      },
    },
  },
  beforeMount () {
    this.permissions = this.$store.getters.permissionByScreen('Customers')
    this.getTop10ProductsByAmount(moment(),moment());
    this.getTop10CustomersByAmount(moment(),moment()); 
    this.getTop10ProductsByWeight(moment(),moment());
  },

  watch: {
    top10ProductsByAmountDate(val) {
      if (val) {
        this.getTop10ProductsByAmount(val.startDate,val.endDate);
      }
    },

    top10CustomersDate(val) {
      if (val) {
        this.getTop10CustomersByAmount(val.startDate,val.endDate);
      }
    },

    top10ProductsByWeightDate(val) {
      if (val) {
        this.getTop10ProductsByWeight(val.startDate,val.endDate);
      }
    }
  },

  methods: {
    goToScreen (path) {
      if (this.permissions.permissionMeta.update) this.$router.push({ path: path })
    },

    prepareSummaryRowForTopProducts(products){
        products.forEach(p => {
            this.totalProdcutsQty = this.totalProdcutsQty +  Number(p.totalQuantity);
            this.totalProductsPrice = this.totalProductsPrice + Number(p.totalAmountData);
        })
    },

    prepareSummaryRowForTopProductsByWeight(products){
        products.forEach(p => {
            this.totalProdcutsByWeightQty = this.totalProdcutsByWeightQty +  Number(p.totalQuantity);
            this.totalProdcutsKgByWeight = this.totalProdcutsKgByWeight +  Number(p.totalProductKg);
            this.totalProductsByWeightPrice = this.totalProductsByWeightPrice + Number(p.totalAmountData);
        })
    },

    prepareSummaryRowForTopCustomers(customers){
      customers.forEach(p => {
            this.totalCustomersQty = this.totalCustomersQty +  Number(p.totalQuantity);
            this.totalCustomersPrice = this.totalCustomersPrice + Number(p.totalAmountData);
        })
    },

    formatNumber(number) {
      return Number(number) % 1 === 0 ? number.toFixed(0) : number.toFixed(2);
    },

    getTop10ProductsByAmount(requestedStartDate, requestedEndDate) {
      let request = {
        startDate: moment(requestedStartDate).format('YYYY-MM-DDT00:00:00+05:30'),
        endDate: moment(requestedEndDate).format('YYYY-MM-DDT23:59:59+05:30'),
        status: ["57615d55-454b-488b-8b65-25617daf6027", "90abf86d-8fbd-42d7-82d6-262689b70e4b"],
      }

      axios({method:'POST', url: Constants.api_url + 'topProducts', data:request}).then(response => {
        if(response && response.data) {
          this.top10ProductsByAmount = response.data;
          this.totalProdcutsQty = 0;
          this.totalProductsPrice = 0;
          this.prepareSummaryRowForTopProducts(response.data);
        }
      })
    },

    getTop10ProductsByWeight(requestedStartDate, requestedEndDate) {
      let request = {
        startDate: moment(requestedStartDate).format('YYYY-MM-DDT00:00:00+05:30'),
        endDate: moment(requestedEndDate).format('YYYY-MM-DDT23:59:59+05:30'),
        status: ["57615d55-454b-488b-8b65-25617daf6027", "90abf86d-8fbd-42d7-82d6-262689b70e4b"],
      }

      axios({method:'POST', url: Constants.api_url + 'topProductsByKg', data:request}).then(response => {
        if(response && response.data) {
          this.top10ProductsByWeight = response.data;
          this.totalProdcutsByWeightQty = 0;
          this.totalProdcutsKgByWeight = 0;
          this.totalProductsByWeightPrice = 0;
          this.prepareSummaryRowForTopProductsByWeight(response.data);
        }
      })
    },

    getTop10CustomersByAmount(requestedStartDate, requestedEndDate) {
      let request = {
        startDate: moment(requestedStartDate).format('YYYY-MM-DDT00:00:00+05:30'),
        endDate: moment(requestedEndDate).format('YYYY-MM-DDT23:59:59+05:30'),
        status: ["57615d55-454b-488b-8b65-25617daf6027", "90abf86d-8fbd-42d7-82d6-262689b70e4b"],
        type: '138c5098-41ff-45c7-b08a-98c0cae54a17'
      }

      axios({method:'POST', url: Constants.api_url + 'topCustomers', data:request}).then(response => {
        if(response && response.data) {
          this.top10CustomersByAmount = response.data;
          this.totalCustomersQty = 0;
          this.totalCustomersPrice = 0;
          this.prepareSummaryRowForTopCustomers(response.data);
        }
      })
    },

    viewDistributor (selectedUser) {
      console.log('selectedUser', selectedUser);
      this.distributor = selectedUser
      this.viewDistributorDialog = true
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .dashboard-widget {
    transition: all 0.3s;
    min-width: 300px;
  }

  .update-col{
    flex:0 0 20%;
    max-width: 200px;
  }
  .update-card{
      border-radius: 6px;
      height: 90px;
      position: relative;
      text-align: end;
      cursor: pointer;
  }
  .update-card-content{
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-weight: 400;
      font-family: 'Quattrocento Sans', sans-serif;
  }
  .count-value{
      font-size: 24px;
  }
  .qty-value{
      font-size: 18px;
      opacity: 0.6;
  }
  .amount-value{
      margin: -8px 0 -26px 0;
      font-size: 16px;
      color: grey;
  }
  .clickable-row{
      cursor: pointer;
  }
   .vue-daterange-picker .dropdown-menu{
      top: 40px;
  }
   .reportrange-text{
      /*width: 200px;*/
      text-align: center;
      padding: 0px 8px;
      border: 1px solid rgba(0,0,0,.14);
      font-size: 14px;
  }
  .ellipsis{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
  }
  .product-name {
      display: inline-block;
      vertical-align: middle;
      width: 150px;
      max-width: 150px;
  }
  .c-name {
      display: inline-block;
      vertical-align: middle;
      width: 140px;
      max-width: 140px;
  }
  .f-12{
      font-size: 12px;
  }
}
</style>
